import React from 'react'
import dynamic from 'next/dynamic'

/**
 * clover framework
 */
const NotReady = dynamic(() => import('@/components/NotReady'), { ssr: false })
const AppLogHome = dynamic(() => import('@/features/AppLogHome'), { ssr: false })
const AuditLogHome = dynamic(() => import('@/features/AuditLogHome'), { ssr: false })
const CmpGuide = dynamic(() => import('@/features/CmpGuide'), { ssr: false })
const CodeHome = dynamic(() => import('@/features/CodeHome'), { ssr: false })
const ErrorCodesHome = dynamic(() => import('@/features/ErrorCodesHome'), { ssr: false })
const JobLogHome = dynamic(() => import('@/features/JobLogList'), { ssr: false })
const JobsHome = dynamic(() => import('@/features/JobList'), { ssr: false })
const PageApiRole = dynamic(() => import('@/features/PageApiRoleHome'), { ssr: false })
const NavMenusHome = dynamic(() => import('@/features/NavMenusHome'), { ssr: false })
const NavPagesHome = dynamic(() => import('@/features/NavPagesHome'), { ssr: false })
const PdsEdit = dynamic(() => import('@/features/PdsEdit'), { ssr: false })
const PdsHome = dynamic(() => import('@/features/PdsHome'), { ssr: false })
const CloverMainHome = dynamic(() => import('@/features/CloverMainHome'), { ssr: false })
const UserMgmtHome = dynamic(() => import('@/features/UserMgmtHome'), { ssr: false })
const RoleUserHome = dynamic(() => import('@/features/RoleUserHome'), { ssr: false })
const RoleSettingsHome = dynamic(() => import('@/features/RoleSettingsHome'), { ssr: false })
const MetaGlossarySearch = dynamic(() => import('@/features/MetaGlossarySearch'), { ssr: false })
const MetaWordSearch = dynamic(() => import('@/features/MetaWordSearch'), { ssr: false })

/**
 * crowd work
 */
const CrowdBasicMainHome = dynamic(() => import('@/features/CrowdBasicMainHome'), { ssr: false })
const CrowdProMainHome = dynamic(() => import('@/features/CrowdProMainHome'), { ssr: false })
const CrowdExtractHome = dynamic(() => import('@/features/CrowdExtractHome'), { ssr: false })
const CrowdInspectionHome = dynamic(() => import('@/features/CrowdInspectionHome'), { ssr: false })
const CrowdProcessingHome = dynamic(() => import('@/features/CrowdProcessingHome'), { ssr: false })
const CrowdProcessingCheckHome = dynamic(() => import('@/features/CrowdProcessingCheckHome'), {
  ssr: false,
})
const CrowdIntegrationHome = dynamic(() => import('@/features/CrowdIntegrationHome'), {
  ssr: false,
})

const WorkerMgmtHome = dynamic(() => import('@/features/WorkerMgmtHome'), { ssr: false })
const AdminMgmtHome = dynamic(() => import('@/features/AdminMgmtHome'), { ssr: false })
const CrowdRawDataHome = dynamic(() => import('@/features/CrowdRawDataHome'), { ssr: false })
const CrowdTaskMgntHome = dynamic(() => import('@/features/CrowdTaskMgntHome'), { ssr: false })
const CrowdMgntViewHome = dynamic(() => import('@/features/CrowdMgntViewHome'), { ssr: false })
const CrowdMgntIntegHome = dynamic(() => import('@/features/CrowdMgntIntegHome'), { ssr: false })
const CrowdTask92MgmtHome = dynamic(() => import('@/features/CrowdTask92MgmtHome'), { ssr: false })
const CrowdTaskCreation = dynamic(() => import('@/features/CrowdTaskCreationHome'), {
  ssr: false,
})
const CrowdSupplyStatusHome = dynamic(() => import('@/features/CrowdBookStatusHome'), {
  ssr: false,
})
const CrowdProgressRateHome = dynamic(() => import('@/features/CrowdProgressRateHome'), {
  ssr: false,
})

const CrowdnotWorkHome = dynamic(() => import('@/features/CrowdNotWorkHome'), {
  ssr: false,
})
const CrowdWorkerStatus = dynamic(() => import('@/features/CrowdWorkerStatusHome'), {
  ssr: false,
})

const WorkerLoginMgmtHome = dynamic(() => import('@/features/WorkerLoginMgmtHome'), {
  ssr: false,
})

const CrowdWorkCostHome = dynamic(() => import('@/features/CrowdWorkCostHome'), {
  ssr: false,
})

const CrowdCostExcessHome = dynamic(() => import('@/features/CrowdCostExcessHome'), {
  ssr: false,
})

const CWUnitPriceMgmtHome = dynamic(() => import('@/features/CWUnitPriceMgmtHome'), {
  ssr: false,
})
const CWExpectWorkCostMgmtHome = dynamic(() => import('@/features/CWExpectWorkCostMgmt'), {
  ssr: false,
})
const CW300WorkCostMgmtHome = dynamic(() => import('@/features/CW300WorkCostMgmt'), {
  ssr: false,
})
const CWWorkCostPaymentCreateHome = dynamic(
  () => import('@/features/CWWorkCostPaymentCreateHome'),
  {
    ssr: false,
  },
)
const CWWorkCostPaymentMgmtHome = dynamic(() => import('@/features/CWWorkCostPaymentMgmtHome'), {
  ssr: false,
})
const PswdResetLogHome = dynamic(() => import('@/features/PswdResetLogHome'), {
  ssr: false,
})
const SettltmentPayExceptMgmtHome = dynamic(
  () => import('@/features/SettltmentPayExceptMgmtHome'),
  {
    ssr: false,
  },
)
const CWWorkStepOpenMgmtHome = dynamic(() => import('@/features/CWWorkStepOpenMgmtHome'), {
  ssr: false,
})
const CWMakeReadyHome = dynamic(() => import('@/features/CWMakeReadyHome'), {
  ssr: false,
})
const CWGoalCurrentRateHome = dynamic(() => import('@/features/CWGoalCurrentRateHome'), {
  ssr: false,
})
const DiversityRate1Home = dynamic(() => import('@/features/DiversityRate1Home'), {
  ssr: false,
})
const DiversityRate2Home = dynamic(() => import('@/features/DiversityRate2Home'), {
  ssr: false,
})
const DiversityRate3Home = dynamic(() => import('@/features/DiversityRate3Home'), {
  ssr: false,
})
const CrowdParaTagging = dynamic(() => import('@/features/CrowdParaTagging'), {
  ssr: false,
})
const CWProfessorMgmtHome = dynamic(() => import('@/features/CWProfessorMgmtHome'), {
  ssr: false,
})

const CrowdJsonCreationHome = dynamic(() => import('@/features/CrowdJsonCreationHome'), {
  ssr: false,
})
const CrowdDataCreationHome = dynamic(() => import('@/features/CrowdDataCreationHome'), {
  ssr: false,
})
const CWQualityAdminMgmtHome = dynamic(() => import('@/features/CWQualityAdminMgmtHome'), {
  ssr: false,
})
const CrowdAdminInteUpdate1 = dynamic(() => import('@/features/CrowdAdminUpdate1Home'), {
  ssr: false,
})
const CrowdAdminInteUpdate2 = dynamic(() => import('@/features/CrowdAdminUpdate2Home'), {
  ssr: false,
})
const CrowdAdminInteUpdate3 = dynamic(() => import('@/features/CrowdAdminUpdate3Home'), {
  ssr: false,
})
const CrowdIntegrationUpdateFormHome = dynamic(
  () => import('@/features/CrowdIntegrationUpdateFormHome'),
  {
    ssr: false,
  },
)


const CrowdProfessor91Home = dynamic(() => import('@/features/CrowdProfessor91Home'), {
  ssr: false,
})

const CrowdProfessor92Home = dynamic(() => import('@/features/CrowdProfessor92Home'), {
  ssr: false,
})

const CrowdProfessor93Home = dynamic(() => import('@/features/CrowdProfessor93Home'), {
  ssr: false,
})

const CWProfessorOpinionPaper1Home = dynamic(
  () => import('@/features/CWProfessorOpinionPaper1Home'),
  {
    ssr: false,
  },
)
const CWProfessorOpinionPaper2Home = dynamic(
  () => import('@/features/CWProfessorOpinionPaper2Home'),
  {
    ssr: false,
  },
)
const CWProfessorOpinionPaper3Home = dynamic(
  () => import('@/features/CWProfessorOpinionPaper3Home'),
  {
    ssr: false,
  },
)
const CWProfessorOpinionPaperCurrent1Home = dynamic(
  () => import('@/features/CWProfessorOpinionPaperCurrent1Home'),
  {
    ssr: false,
  },
)
const CWProfessorOpinionPaperCurrent2Home = dynamic(
  () => import('@/features/CWProfessorOpinionPaperCurrent2Home'),
  {
    ssr: false,
  },
)
const CWProfessorOpinionPaperCurrent3Home = dynamic(
  () => import('@/features/CWProfessorOpinionPaperCurrent3Home'),
  {
    ssr: false,
  },
)

export interface ISceneComponent {
  url: string
  title: string
  component: React.ComponentType | React.ReactNode
}

/**
 * 배열을 받아 키가 url 이고 값이 {url, title, component} 인 객체를 리턴한다
 * reduce가 사용됐기 때문에 한 객체안에 전체 키와 값이 들어감 (배열아님)
 */
function createScenes(
  scenes: Array<[string, string, React.ComponentType]>,
): Record<string, ISceneComponent> {
  return scenes.reduce((acc, cur) => {
    const [url, title, component] = cur
    acc[url] = { url, title, component }
    return acc
  }, {} as Record<string, ISceneComponent>)
}

// TODO 제목에 비어 있는 부분 채워야 함
// 메인 레이아웃 안에 포함할 URL만 추가한다
export const SCENES: Record<string, ISceneComponent> = createScenes([
  ['/meta/words', '메타 단어', MetaWordSearch],
  ['/meta/glossary', '메타 용어', MetaGlossarySearch],
  ['/code', '코드 조회', CodeHome],
  ['/log/error-codes', '에러 코드 관리', ErrorCodesHome],
  ['/log/app-logs', '시스템 로그', AppLogHome],
  ['/log/audit-logs', 'AUDIT 로그', AuditLogHome],
  ['/log/job-logs', '잡 로그', JobLogHome],
  ['/log/jobs', '잡 목록', JobsHome],
  ['/nav/list', '메뉴 모음', NavMenusHome],
  ['/nav/pages', '페이지 목록', NavPagesHome],
  ['/pds/list', '자료실', PdsHome],
  ['/pds/edit', '자료실 등록', PdsEdit],
  ['/pds/edit/[pdsId]', '자료실 수정', PdsEdit],
  ['/cmp-guide', 'Component 가이드', CmpGuide],
  ['/role/page-api-role', '페이지 권한', PageApiRole],
  ['/role/settings', '권한 설정', RoleSettingsHome],
  ['/role/user', '사용자 권한 관리', RoleUserHome],
  ['/clover-main', 'CLOVER 메인', CloverMainHome],
  ['/user-mgmt', '사용자관리', UserMgmtHome],
  ['/crowd-work/basic-main-home', '메인', CrowdBasicMainHome],
  ['/crowd-work/pro-main-home', '메인', CrowdProMainHome],
  ['/crowd-work/extract', '추출', CrowdExtractHome],
  ['/crowd-work/inspection', '정제', CrowdInspectionHome],
  ['/crowd-work/processing', '가공 작업(라벨링)', CrowdProcessingHome],
  ['/crowd-work/processing-check', '가공 작업(라벨링)', CrowdProcessingCheckHome],
  ['/crowd-work/integration-check', '통합검수', CrowdIntegrationHome],
  ['/crowd-work/worker-mgmt', '워커 관리', WorkerMgmtHome],
  ['/crowd-work/admin-mgmt', '관리자 관리', AdminMgmtHome],
  ['/crowd-work/mgnt-integ', '통합검수 관리', CrowdMgntIntegHome],
  ['/crowd-work/raw-data', '원시데이터 등록/조회', CrowdRawDataHome],
  ['/crowd-work/task-mgnt', 'Task 관리', CrowdTaskMgntHome],
  ['/crowd-work/task92-mgmt', '9-2Task 관리', CrowdTask92MgmtHome],
  ['/crowd-work/mgnt-view', '관리자 뷰 화면', CrowdMgntViewHome],
  ['/crowd-work/task-creation', 'Task 생성', CrowdTaskCreation],
  ['/crowd-work/book-supply-status', '도서수급현황', CrowdSupplyStatusHome],
  ['/crowd-work/work-progress-rate', '작업진척율', CrowdProgressRateHome],
  ['/crowd-work/not-work', '작업미수행 워커', CrowdnotWorkHome],
  ['/crowd-work/worker-status', '워커 현황', CrowdWorkerStatus],
  ['/crowd-work/worker-login', '로그인 관리', WorkerLoginMgmtHome],
  ['/crowd-work/work-cost', '작업비 조회', CrowdWorkCostHome],
  ['/crowd-work/cost-excess', '당월 300만원 초과자', CrowdCostExcessHome],
  ['/crowd-work/unit-price-mgmt', '공정별 단가 관리', CWUnitPriceMgmtHome],
  ['/crowd-work/expect-work-cost-mgmt', '예상 워커별 작업비 조회', CWExpectWorkCostMgmtHome],
  ['/crowd-work/300-work-cost', '예상작업비 300만원 관리', CW300WorkCostMgmtHome],
  ['/crowd-work/pswd-reset-log', '비밀번호 초기화 로그', PswdResetLogHome],
  ['/crowd-work/work-cost-payment-create', '지급 작업비 생성', CWWorkCostPaymentCreateHome],
  ['/crowd-work/work-cost-payment-mgmt', '작업비 지급내역', CWWorkCostPaymentMgmtHome],
  ['/crowd-work/settlement-pay-except', '지급 제외금액 관리', SettltmentPayExceptMgmtHome],
  ['/crowd-work/work-step-open-mgmt', '지급 제외금액 관리', CWWorkStepOpenMgmtHome],
  ['/crowd-work/make-ready', '보정 필요', CWMakeReadyHome],
  ['/crowd-work/goal-current-rate', '보정 필요', CWGoalCurrentRateHome],
  ['/crowd-work/diversity/di1', '9-1 다양성 통계', DiversityRate1Home],
  ['/crowd-work/diversity/di2', '9-2 다양성 통계', DiversityRate2Home],
  ['/crowd-work/diversity/di3', '9-3 다양성 통계', DiversityRate3Home],
  ['/crowd-work/91para-tag', '9-1 단락별 태깅수', CrowdParaTagging],
  ['/crowd-work/json-creation', 'Json 생성', CrowdJsonCreationHome],
  ['/crowd-work/data-creation', '원천 데이터 생성', CrowdDataCreationHome],
  ['/crowd-work/quality-admin-mgmt', '품질관리자 관리', CWQualityAdminMgmtHome],
  ['/crowd-work/admin-inteup/uphome1', '9-1 질의응답쌍', CrowdAdminInteUpdate1],
  ['/crowd-work/admin-inteup/uphome2', '9-2 삽화설명문', CrowdAdminInteUpdate2],
  ['/crowd-work/admin-inteup/uphome3', '9-3 줄거리 요약', CrowdAdminInteUpdate3],
  ['/crowd-work/admin-inteup/uphome3', '9-3 줄거리 요약', CrowdAdminInteUpdate3],
  ['/crowd-work/admin-inteup/update-form', '통합검수업데이트폼', CrowdIntegrationUpdateFormHome],
  ['/crowd-work/professor-mgmt', '자문교수 관리', CWProfessorMgmtHome],
  ['/crowd-work/professor/pr1', '9-1 질의응답쌍(교수)', CrowdProfessor91Home],
  ['/crowd-work/professor/pr2', '9-2 삽화설명문(교수)', CrowdProfessor92Home],
  ['/crowd-work/professor/pr3', '9-3 줄거리 요약(교수)', CrowdProfessor93Home],
  ['/crowd-work/professor/opinion-paper1', '1차 검수결과 의견서', CWProfessorOpinionPaper1Home],
  ['/crowd-work/professor/opinion-paper2', '2차 검수결과 의견서', CWProfessorOpinionPaper2Home],
  ['/crowd-work/professor/opinion-paper3', '3차 검수결과 의견서', CWProfessorOpinionPaper3Home],
  [
    '/crowd-work/professor/opinion-paper-current1',
    '1차 검수결과 의견서 작성현황',
    CWProfessorOpinionPaperCurrent1Home,
  ],
  [
    '/crowd-work/professor/opinion-paper-current2',
    '2차 검수결과 의견서 작성현황',
    CWProfessorOpinionPaperCurrent2Home,
  ],
  [
    '/crowd-work/professor/opinion-paper-current3',
    '3차 검수결과 의견서 작성현황',
    CWProfessorOpinionPaperCurrent3Home,
  ],
])

// url(키)로 값을 리턴해준다
export const findSceneByUrl = (url: string): ISceneComponent | undefined => {
  if (url.endsWith('/')) {
    url = url.replace(/\/+$/, '')
  }
  const scene = SCENES[url]
  return scene ?? undefined
}

// url컴포넌트를 받아서 리액트 노드로 변경해준다 기존 자연스럽게 되던거
export const createSceneElement = (
  component: React.ComponentType | React.ReactNode,
  props?: any,
): React.ReactNode | undefined => {
  if (React.isValidElement(component)) {
    return React.cloneElement(component, props)
  }

  const Comp = component as React.ComponentType
  return <Comp {...props} />
}
